


















































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@/mixins/table-dialog-form-component';
import { RoleEntityModel, RoleQueryModel } from '@/entity-model/role-entity';
import RoleService from '@/service/role-v2';
import RoleDetailComponent from '@/pages/admin-dashboard/corp-detail-drawer.vue';
import { UserStoreModule } from '@/store/modules/user';
import LinkUserDialog from './link-user-dialog.vue';

@Component({
    components: {
        'role-detail-drawer': RoleDetailComponent,
        'link-user-dialog': LinkUserDialog
    }
})
export default class RoleListComponent extends TableDialogFormComponent<RoleEntityModel, RoleQueryModel> {
    RoleEntityModel = RoleEntityModel;
    created() {
        this.initTable({
            service: RoleService,
            queryModel: new RoleQueryModel(),
            tableColumns: RoleEntityModel.getTableColumns()
        });
        this.getList();
    }
    configClick(model: RoleEntityModel) {
        (this.$refs.roleDetailDrawer as RoleDetailComponent).drawerRoleOpen(model.id, UserStoreModule.CurrentUserPermissionType);
        (this.$refs.roleDetailDrawer as RoleDetailComponent).drawerTitle = model.name;
    }
    linkUserClick(model: RoleEntityModel) {
        (this.$refs.linkUserDialog as LinkUserDialog).dialogOpen(model.id);
    }
    formDialogOK() {
        this.getList();
    }
}

