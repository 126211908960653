


































































import { Component, Ref } from 'vue-property-decorator';
import TransformComponent from '@/mixins/transform-component';
import { GroupEntityType } from '@/model/enum';
import { DeviceTypeEntityModel, DeviceTypeQueryModel } from '@/entity-model/device-type-entity';
import FacilityTypeService from '@/service/facility-type';
import { FormControlType } from '@/model/form-control';
import DeviceTypeService from '@/service/device-type';
import FacilityGroupService from '@/service/facility-group';
import UserService from '@/service/user-v2';
import { UserQueryModel } from '@/entity-model/user-entity';
import OrgService from '@/service/org';

@Component
export default class LinkUserDialog extends TransformComponent {
    roleId: string = null;
    orgTreeData: Array<any> = null;
    userService: UserService = new UserService();
    orgIdsSearch: Array<string> = null;
    userNameSearch: string = null;

    created() {
        OrgService.getOrgTree().then(res => {
            this.orgTreeData = res;
        });
    }

    get ListData() {
        const hasNameFilter = !!this.userNameSearch;
        const hasOrgFilter = this.orgIdsSearch && this.orgIdsSearch.length > 0;
        let filterData = this.listData;
        if (hasNameFilter) {
            filterData = _.filter(filterData, item => {
                return item.name.indexOf(this.userNameSearch) > -1;
            });
        }
        if (hasOrgFilter) {
            filterData = _.filter(filterData, item => {
                return _.difference(item.orgIds, this.orgIdsSearch).length < item.orgIds.length;
            });
        }
        return filterData;
    }

    dialogOpen(roleId: string) {
        this.roleId = roleId;
        this.dialogVisible = true;
        this.rightDataList = [];
        this.listData = [];
        this.userService.getunLinkUsers(this.roleId).then(res => {
            this.listData = _.map(res, item => {
                return {
                    id: item.id,
                    name: item.name,
                    orgIds: item.orgIds,
                    // roleId: item.roleId,
                    check: item.roleId === roleId
                };
            });
            this.rightDataList = _.filter(_.map(res, item => {
                return {
                    id: item.id,
                    name: item.name,
                    check: item.roleId === roleId
                };
            }), item => item.check);
        }).catch(err => {
            this.dialogVisible = false;
        });
    }

    dialogOK() {
        return this.userService.saveLinkUser(this.roleId, _.map(this.rightDataList, item => item.id)).then((ret) => {
            this.dialogClose();
            this.$emit('dialogOK');
        });
    }

    dialogClose(): void {
        this.listData = null;
        this.rightDataList = [];
        this.dialogVisible = false;
    }
}
